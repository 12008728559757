import {
  BreakpointLabel,
  Breakpoints,
  Theme,
  audiDarkTheme,
  responsiveStyles,
} from '@audi/audi-ui-react';
import throttle from 'lodash.throttle';
import * as React from 'react';
import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';

import { FA_LOCATOR_ID, Video, VideoPlayerProps, Videos } from '../../../types';
import { Context } from '../../Context';
import { logVideoPlayerComponentTrackingData } from '../../utils/debugging';
import parseAspectRatio from '../../utils/parse-aspect-ratio';
import BackgroundImageComponent from '../background-image/background-image';
import { Controller } from '../controller/controller';
import { useIsFirstRender } from '../hooks/useIsFirstRender';
import { useTracking } from '../hooks/useTracking';
import { useSeekingStatus } from '../hooks/video/useSeekingStatus';
import { useVideo } from '../hooks/video/useVideo';

interface WrapperProps {
  isCover?: boolean;
  videos: Videos;
}

interface WrapperPropsWithTheme extends WrapperProps {
  theme: Theme;
}

const Wrapper = styled.div<WrapperProps>`
  ${({ isCover, videos, theme }: WrapperPropsWithTheme) =>
    isCover
      ? // Ignore layout tests:
        /* istanbul ignore next */
        css`
          height: 100%;
        `
      : css`
          background: #000;

          ${responsiveStyles({ 'padding-bottom': createStyles(videos) }, theme.breakpoints)}

          overflow: hidden;
        `}
  position: relative;
  width: 100%;
`;

const createStyles = (videos: Videos): Partial<Breakpoints<string>> => {
  return Object.fromEntries(
    Object.entries(videos).map(([breakpoint, video]) => {
      const [x, y] = parseAspectRatio(video.aspectRatio);
      // swap sides for "padding-bottom trick"
      const aspectRatioPercentage = (y * 100) / x;

      return [breakpoint, `${aspectRatioPercentage}%`];
    }),
  );
};

interface VideoProps {
  isCover?: boolean;
}

const VideoComponent = styled.video<VideoProps>`
  height: 100%;
  ${({ isCover }): FlattenSimpleInterpolation =>
    !isCover &&
    css`
      left: 0;
      position: absolute;
      top: 0;
    `};
  object-fit: ${({ isCover }): string => {
    // Ignore layout tests:
    /* istanbul ignore next */
    return isCover ? 'cover' : 'contain';
  }};
  width: 100%;
`;

/*
 * the hash at the end of the URL fixes a bug on Safari when the first
 * frame is not used as poster image when no custom poster image is defined
 */
const fixSrc = (poster: boolean, src: string) => {
  return poster ? src : `${src}#t=0.001`;
};

export const VideoPlayer: FC<VideoPlayerProps> = ({ videos, cover, playsInline, isFormatable }) => {
  const ref = useRef<HTMLVideoElement | null>(null);
  const isFirstRender = useIsFirstRender();

  const { videoService, featureAppEnv } = useContext(Context);

  /* In order to autoplay videos on modern browsers the should be initially muted */
  const [isMuted, setIsMuted] = useState(videos.xs.autoPlay || videos.xs.muted);
  const [showReplayButton, setShowReplayButton] = useState(false);
  const [isPlaying, setIsPlaying] = useState(videos.xs.autoPlay);
  const [isAtStart, setIsAtStart] = useState(true);
  const [wasStartedInitially, setWasStartedInitially] = useState(false);

  const isSeeking = useSeekingStatus(ref);
  const { videoProps } = useVideo(ref, videos.xs);

  const onEnded = useCallback(() => {
    setShowReplayButton(true);
    setIsPlaying(false);
  }, []);

  const onPlay = useCallback(() => {
    if (!wasStartedInitially) {
      setWasStartedInitially(true);
    }
    setShowReplayButton(false);
    setIsPlaying(true);
  }, []);

  const toggleMuted = useCallback(() => {
    setIsMuted(videoService?.toggleMuted());
  }, [videoService]);

  const hasPosterImage = useMemo<boolean>(() => {
    return videos.xs.poster !== undefined;
  }, [videos]);

  useEffect(() => {
    videoService?.registerVideoRef(ref);
    return (): void => {
      videoService?.unregisterVideoRef();
    };
  }, [videoService]);

  useEffect(() => {
    const videoElement = ref.current;
    const updateTime = throttle((): void => {
      setIsAtStart(() => {
        return videoElement.currentTime <= 0.001;
      });
      setIsMuted(() => {
        return videoElement.muted || videoElement.volume === 0;
      });

      tracking.videoPercentagePlayedEvent(videoElement.currentTime, videoElement.duration);
    }, 250);

    const setIsNotPlaying = (): void => {
      setIsPlaying(false);
    };

    videoElement?.addEventListener('timeupdate', updateTime);

    videoElement?.addEventListener('pause', setIsNotPlaying);

    return (): void => {
      videoElement?.removeEventListener('timeupdate', updateTime);

      videoElement?.removeEventListener('pause', setIsNotPlaying);
    };
  }, []);

  useEffect(() => {
    if (isAtStart && !isPlaying) {
      setShowReplayButton(false);
    }
  }, [isAtStart, isPlaying]);

  // Setup tracking START
  const tracking = useTracking(ref, {
    implementer: 37,
  });

  logVideoPlayerComponentTrackingData('tracking init started');

  React.useEffect(() => {
    if (!isFirstRender) {
      tracking.videoStartEvent();
    }
  }, [wasStartedInitially]);

  React.useEffect(() => {
    if (!isFirstRender) {
      if (isAtStart) {
        // skip this playing status change because of user has started the video
        return;
      }
      if (ref.current.currentTime >= ref.current.duration) {
        // skip this playing status change because video reached the end
        return;
      }
      if (ref.current.readyState === 1 || ref.current.seeking || isSeeking) {
        // skip this playing status change because video is seeking
        return;
      }

      // playing status must have been toggled by the user
      if (isPlaying) {
        tracking.videoResumeEvent();
      } else {
        tracking.videoPauseEvent();
      }
    }
  }, [isPlaying]);

  React.useEffect(() => {
    tracking.sendReadyEvent();
    tracking.registerImpressionTracking();

    logVideoPlayerComponentTrackingData('tracking init finished');
  }, []);
  // Setup tracking END

  return (
    <Wrapper isCover={cover} videos={videos} data-test-id={FA_LOCATOR_ID}>
      <VideoComponent
        autoPlay={videos.xs.autoPlay}
        isCover={cover}
        key={videos.xs.src}
        loop={videos.xs.loop}
        // In order to autoplay videos on modern browsers the should be initially muted
        muted={videos.xs.autoPlay || videos.xs.muted}
        controls={videos.xs.nativeControls}
        onEnded={onEnded}
        onPlay={onPlay}
        playsInline={playsInline}
        poster={videos.xs.nativeControls && hasPosterImage ? videos.xs.poster : ''}
        aria-label={videos.xs.nativeControls && hasPosterImage ? videos.xs.alt : ''}
        preload="metadata"
        // @ts-expect-error ref is fine
        ref={ref}
        {...videoProps}
      >
        {Object.entries(videos)
          .map(
            ([breakpoint, video]: [breakpoint: BreakpointLabel, video: Video]): [number, Video] => [
              audiDarkTheme.breakpoints[breakpoint],
              video,
            ],
          )
          .sort(([a], [b]) => b - a)
          .map(([minWidth, { src }]) => (
            <source
              media={minWidth > 0 ? `(min-width:${minWidth}px)` : undefined}
              src={fixSrc(hasPosterImage, src)}
              key={src}
            />
          ))}
      </VideoComponent>
      <Controller
        {...{ featureAppId: featureAppEnv.featureAppId, isMuted, toggleMuted }}
        play={async () => {
          try {
            await videoService?.play();
            logVideoPlayerComponentTrackingData('play');
          } catch (error) {
            // TODO error handling has not yet been specified
            // TODO implement tests once error handling is implemented
            /* istanbul ignore next */
            // eslint-disable-next-line no-console
            console.error(error);
          }
        }}
        replay={async (): Promise<void> => {
          logVideoPlayerComponentTrackingData('replay');
          setIsPlaying(true);
          setShowReplayButton(false);

          try {
            await videoService?.play();
          } catch (error) {
            // TODO error handling has not yet been specified
            // TODO implement tests once error handling is implemented
            /* istanbul ignore next */
            // eslint-disable-next-line no-console
            console.error(error);
          }
        }}
        showPlayButton={
          !videos.xs.nativeControls && !isPlaying && !(!videos.xs.loop && showReplayButton)
        }
        showReplayButton={!videos.xs.nativeControls && !videos.xs.loop && showReplayButton}
        showToggleMuteButton={!videos.xs.nativeControls && !videos.xs.muted}
      />
      {!videos.xs.nativeControls &&
        hasPosterImage &&
        !isPlaying &&
        !showReplayButton &&
        isAtStart && <BackgroundImageComponent {...{ isFormatable }} videos={videos} />}
    </Wrapper>
  );
};
